import React, { useState } from "react";
import "./styles.css";
import TextField from "../../Components/Form/TextField";
import TextAreaField from "../../Components/Form/TextAreaField";
import CheckBoxField from "../../Components/Form/CheckBoxField";
import SendButton from "../../Components/Form/SendButton";
import sendAni from "../../assets/Images/letter_send.png";
import phoneIcon from "../../assets/Images/bxs_phone-call.png";
import emailIcon from "../../assets/Images/ic_sharp-email.png";
import locIcon from "../../assets/Images/carbon_location-filled.png";
import ErrorPopup from "../../Components/Popups/ErrorPopup";
import Loader from "../../Components/Form/Loader";

function QuotesPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceCity, setServiceCity] = useState("");
  const [services, setServices] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      firstName &&
      lastName &&
      email &&
      phoneNumber &&
      serviceCity &&
      services.length > 0
    ) {
      console.log("Submitted");
      if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
        setPopup({
          errorType: "Invalid email address",
          error: "Please enter your valid email address",
        });
      } else if (phoneNumber.length != 10) {
        setPopup({
          errorType: "Invalid phone number",
          error:
            "Phone number should be not include country code and must be 10 digit only",
        });
      } else {
        // Form Submission
        var data = {
          FirstName: firstName,
          LastName: lastName,
          CompanyName: company,
          EmailId: email,
          PhoneNumber: phoneNumber,
          ServiceCity: serviceCity,
          Services: services,
          Message: message,
        };
        console.log(data, "Sending..", services, services.length);
        setLoader("Sending...");
        fetch("https://formspree.io/f/myyqoved", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.next === "/thanks?language=en") {
              setLoader("Thank you, we'll get back to you soon");
            }
          });
      }
    } else if (!firstName) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your first name",
      });
    } else if (!lastName) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your last name",
      });
    } else if (!email) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your email address",
      });
    } else if (!phoneNumber) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your phone number",
      });
    } else if (!serviceCity) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter City in which you want services",
      });
    } else if (!services.length > 0) {
      setPopup({
        errorType: "Required Field",
        error: "Please select your services in which you are intrested",
      });
    }
  };
  return (
    <div className="page">
      {popup ? (
        <ErrorPopup
          errorType={popup.errorType}
          error={popup.error}
          closePopup={() => {
            setPopup(false);
          }}
        />
      ) : null}
      <h1 id="quotesPageTitle">Obtain a Quote</h1>
      <h2 id="quotesPageSubTitle">
        Your Security, Our Expertise: Request a Custom Quote Today
      </h2>
      <div className="quotesSection">
        <div className="quotesForm">
          {loader ? (
            <Loader
              loaderText={loader == true ? "Sending" : loader}
              hideLoader={loader == "Thank you, we'll get back to you soon"}
            />
          ) : null}
          <form onSubmit={handleFormSubmit}>
            <div id="column">
              <TextField
                value={firstName}
                setValue={setFirstName}
                name="firstName"
                placeholder="First Name*"
              ></TextField>
              <div id="spaceBetField"></div>
              <TextField
                value={lastName}
                setValue={setLastName}
                name="lastName"
                placeholder="Last Name*"
              ></TextField>
            </div>
            <div id="column">
              <TextField
                value={company}
                setValue={setCompany}
                name="companyName"
                placeholder="Company Name"
              ></TextField>
            </div>
            <div id="column">
              <TextField
                value={email}
                setValue={setEmail}
                name="emailAddress"
                placeholder="Email Address*"
              ></TextField>
              <div id="spaceBetField"></div>
              <TextField
                value={phoneNumber}
                setValue={setPhoneNumber}
                name="phoneNumber"
                placeholder="Phone Number*"
              ></TextField>
            </div>
            <div id="column">
              <TextField
                value={serviceCity}
                setValue={setServiceCity}
                name="serviceLocation"
                placeholder="Service City*"
              ></TextField>
            </div>
            <div id="column">
              <CheckBoxField
                value={services}
                setValue={setServices}
                name="services"
                placeholder="Interested Services*"
                options={[
                  "Traffic Control",
                  "Regular Patrol",
                  "Elevator Operator",
                  "Key Running",
                  "Fire Watch",
                ]}
              ></CheckBoxField>
            </div>
            <div id="column">
              <TextAreaField
                value={message}
                setValue={setMessage}
                name="message"
                placeholder="Message"
              ></TextAreaField>
            </div>
            <div id="sendButtonContainer">
              <SendButton
                onClick={handleFormSubmit}
                text="Send Request"
              ></SendButton>
              <br />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default QuotesPage;
