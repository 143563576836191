// AboutPage.js
import React from "react";
import "./AboutPage.scss";
import madison from "../../assets/Images/madison.jpg";
import worker from "../../assets/Images/gladiatorworker.jpg";

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="about-section">
        <h1>About Gladiator Security Group</h1>
        <div className="content-container">
          <p>
            Gladiator Security Group is a leading security service provider
            dedicated to safeguarding various establishments and ensuring a
            secure environment. With a strong focus on construction site
            security, our team plays a pivotal role in securing construction
            projects across different locations.
          </p>
          <div className="image-container">
            <img src={worker} alt="Gladiator Security Group" />
          </div>
        </div>
      </div>
      <div className="contracted-section">
        <h3>Contracted to Madison Group and the Nobu Building</h3>
        <div className="content-container">
          <p>
            We take immense pride in our partnerships, and one of our esteemed
            clients is Madison Group. As their trusted security partner, we are
            entrusted to provide top-notch security services for their projects.
            Notably, our team is currently contracted to work at the prestigious
            Nobu building, a symbol of luxury and sophistication.
          </p>
          <div className="image-container">
            <img src={madison} alt="Madison Group and Nobu Building" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
