// Header.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import GladiatorLogo from "../../assets/Logo/gladiatorlogo.svg";
import MenuIcon from "../../assets/Images/ci_menu-alt-01.svg";
import CloseIcon from "../../assets/Images/akar-icons_cross.svg";
import LinkContainer from "./HeaderComponents/LinkContainer/LinkContainer";
import GetQuoteButton from "./HeaderComponents/GetQuoteButton/GetQuoteButton"; // Import the custom button component
import "./Header.scss";
import { NavLink } from "react-router-dom";

function Header() {
  const [visiblity, setVisiblity] = useState(false);
  return (
    <header className="mainHeader">
      <Link to="/home" className="mainHeader__logo-link">
        <img
          src={GladiatorLogo}
          className="mainHeader__logo"
          alt="Gladiator Logo"
        />
      </Link>
      <div id="pcHeader">
        <h1 className="mainHeader__title">Gladiator Security Group</h1>

        <LinkContainer />

        <div className="button-container">
          <GetQuoteButton /> {/* Add the custom button component */}
        </div>
      </div>
      <div id="mobileHeader">
        <img
          src={MenuIcon}
          className="menuIcon"
          alt="Menu Icon"
          onClick={() => {
            setVisiblity(true);
          }}
        />
      </div>
      {visiblity ? (
        <div id="mobileMenu">
          <img
            src={CloseIcon}
            id="CloseIcon"
            onClick={() => {
              setVisiblity(false);
            }}
            alt="Close"
          />
          <div id="Menu">
            <NavLink
              to="/about"
              onClick={() => {
                setVisiblity(false);
              }}
              className="link"
              activeClassName="active"
            >
              About
            </NavLink>
            <NavLink
              to="/services"
              onClick={() => {
                setVisiblity(false);
              }}
              className="link"
              activeClassName="active"
            >
              Services
            </NavLink>
            <NavLink
              to="/contact"
              onClick={() => {
                setVisiblity(false);
              }}
              className="link"
              activeClassName="active"
            >
              Contact
            </NavLink>
            <div className="link"></div>

            <NavLink
              to="/quotes"
              onClick={() => {
                setVisiblity(false);
              }}
              className="link underlined"
              activeClassName="active"
            >
              Get Quote
            </NavLink>
          </div>
        </div>
      ) : null}
    </header>
  );
}

export default Header;
