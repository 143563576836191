import React from "react";
import "./styles.css";

export default function TextField(props) {
  return (
    <div id="textAreaFieldContainer">
      <label id="textAreaFieldLabel">{props.placeholder}</label>
      <textarea
        id="textAreaFieldInput"
        value={props.value}
        rows={props.rows ? props.rows : 5}
        onChange={(e) => props.setValue(e.target.value)}
      />
    </div>
  );
}
