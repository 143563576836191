import React from "react";
import { Link } from "react-router-dom";
import "./Card.scss";

const Card = ({ title, imageSrc, link }) => {
  return (
    <div className="card">
      <Link to={link} className="card-link">
        <div className="image-container">
          <img src={imageSrc} alt={title} />
        </div>
        <div className="card-content">
          <h3>{title}</h3>
        </div>
      </Link>
    </div>
  );
};

export default Card;
