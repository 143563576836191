// Hero.js
import React from "react";
import heroimage from "../../assets/Images/heroimage2.jpg";
import "./Hero.scss";

const Hero = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="hero-text">
          <div className="content-wrapper">
            <h2>Protecting What Matters Most</h2>
            <p>
              At Gladiator Security Group, we are your dedicated partners in
              safeguarding your world. Our expert security guards provide
              vigilant protection for your business, property, and people,
              ensuring your peace of mind day and night.
            </p>
          </div>
        </div>
        <img src={heroimage} alt="Hero" className="hero-image" />
      </div>
    </div>
  );
};

export default Hero;
