import React from "react";
import "./styles.css";

export default (props) => {
  return (
    <div className="loaderBackground">
      {props.hideLoader ? null : <div className="loader"></div>}
      <p>{props.loaderText}</p>
    </div>
  );
};
