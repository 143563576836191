// LinkContainer.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./LinkContainer.scss";

function LinkContainer() {
  return (
    <nav>
      <div className="linkContainer">
        <NavLink to="/about" className="link" activeClassName="active">
          About
        </NavLink>
        <NavLink to="/services" className="link" activeClassName="active">
          Services
        </NavLink>
        <NavLink to="/contact" className="link" activeClassName="active">
          Contact
        </NavLink>
      </div>
    </nav>
  );
}

export default LinkContainer;
