import React from "react";
import "./styles.css";

export default (props) => {
  return (
    <div className="frame" onClick={props.onClick}>
      <div className="text-wrapper">{props.text}</div>
    </div>
  );
};
