import React from "react";
import "./styles.css";

export default function TextField(props) {
  return (
    <div id="textFieldContainer">
      <label id="textFieldLabel">{props.placeholder}</label>
      <input
        id="textFieldInput"
        type={props.fieldType ? props.fieldType : "text"}
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
      />
    </div>
  );
}
