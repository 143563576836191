import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <h3 className="footer__text">
        Copyright © 2023 Gladiator Security Group. All Rights Reserved.
      </h3>
    </div>
  );
}

export default Footer;
