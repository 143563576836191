import React from "react";
import "./HomePage.scss";
import Hero from "../../Components/Home/Hero";
import Card from "../../Components/Home/Card";
import about from "../../assets/Images/aboutimage.jpg";
import contact from "../../assets/Images/contactimage.jpg";
import services from "../../assets/Images/servicesimage.jpg";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <div className="cards-container">
        <Card title="About" imageSrc={about} link="/about" />
        <Card title="Services" imageSrc={services} link="/services" />
        <Card title="Contact" imageSrc={contact} link="/contact" />
      </div>
    </div>
  );
};

export default HomePage;
