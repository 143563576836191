// ServicesPage.js
import React from "react";
import "./ServicesPage.scss";
import trafficImage from "../../assets/Images/IMG_0884.jpg";
import keyRunnerImage from "../../assets/Images/IMG_0869.jpg";
import patrolImg from "../../assets/Images/IMG_0769.jpg";
import fireImg from "../../assets/Images/IMG_0866.jpg";
import ElevatorImg from "../../assets/Images/IMG_0764.jpg";
// Add more images for other security services if needed

const ServicesPage = () => {
  return (
    <div className="services-page">
      <h1>Services</h1>
      <div className="service">
        <div className="service-content">
          <h2>Traffic Control Service</h2>
          <p>
            In a world where safe and efficient transportation is paramount, our
            Traffic Control Service leads the way in managing and optimizing
            traffic flow. Our highly trained personnel ensure the safety and
            smooth movement of vehicles and pedestrians. With a strong focus on
            safety and traffic flow optimization, we excel in managing traffic
            for events, construction projects, and emergencies, making commutes
            safer, smoother, and more efficient.
          </p>
        </div>
        <img src={trafficImage} alt="Security Service 1" />
      </div>
      <div className="service">
        <div className="service-content">
          <h2>Regular Patrol Service</h2>
          <p>
            Secure your property and assets with our proactive Regular
            Patrolling Service. Our highly trained security professionals
            provide round-the-clock surveillance and vigilance. Their visible
            presence deters potential threats, while randomized patrols keep
            security unpredictable for wrongdoers. Thorough property
            inspections, swift emergency responses, and access control are all
            part of our service, ensuring your property remains consistently
            monitored and protected.
          </p>
        </div>
        <img src={patrolImg} alt="Security Service 2" />
      </div>
      <div className="service">
        <div className="service-content">
          <h2>Elevator Operator Service</h2>
          <p>
            Experience the epitome of personalized assistance with our Elevator
            Operator Service. Our dedicated operators are not just
            professionals; they are your hosts in vertical transportation. They
            greet you with a warm smile, ensuring a safe and efficient journey.
            With a focus on safety and courtesy, our operators take your
            building experience to the next level. From offering helpful
            information to accommodating special requests, we enhance your
            building's experience in an exemplary way.
          </p>
        </div>
        <img src={ElevatorImg} alt="Security Service 2" />
      </div>
      <div className="service">
        <div className="service-content">
          <h2>Key Running Service</h2>
          <p>
            Safely transport keys and access credentials with our Key Running
            Service. We prioritize the secure delivery of keys to remote
            locations, among personnel, and for various property access needs.
            Our service employs strict security protocols, ensuring the
            confidential transport of keys. Whether you need scheduled or
            on-demand service, we provide a reliable and secure key transport
            solution, allowing you to focus on what matters most.
          </p>
        </div>
        <img src={keyRunnerImage} alt="Security Service 2" />
      </div>
      <div className="service">
        <div className="service-content">
          <h2>Fire Watch Service</h2>
          <p>
            During critical moments, our Fire Watch Service stands as the
            guardian of fire safety. Our highly trained personnel respond
            swiftly to emergencies, ensuring your property remains compliant
            with fire safety regulations. With continuous vigilance, detailed
            documentation, and customized solutions, we provide peace of mind
            when your regular fire protection systems are compromised. Your
            safety and compliance are our top priorities.
          </p>
        </div>
        <img src={fireImg} alt="Security Service 2" />
      </div>
      {/* Add more services here with the same structure */}
    </div>
  );
};

export default ServicesPage;
