import React, { useState } from "react";
import "./styles.css";

export default (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Handler for when a checkbox is clicked
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (selectedOptions.includes(value)) {
      // Remove the value if it's already selected
      setSelectedOptions(
        selectedOptions.filter((interest) => interest !== value)
      );
    } else {
      // Add the value if it's not selected
      setSelectedOptions([...selectedOptions, value]);
    }
    props.setValue([...selectedOptions, value]);
  };
  return (
    <div id="textFieldContainer">
      <label id="checkBoxFieldLabel">{props.placeholder}</label>
      <br />
      <div id="options">
        {props.options.map((option) => (
          <label key={option} className="checkBoxOptionLabel">
            <input
              type="checkbox"
              name="interests"
              className="checkBoxOption"
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={handleCheckboxChange}
            />
            {option}
          </label>
        ))}
      </div>
    </div>
  );
};
