import React, { Component } from "react";
import "./styles.css";

export default (props) => {
  return (
    <div className="popup">
      <div>
        <button onClick={props.closePopup}>
          <svg
            pointerEvents="none"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M18.6699 6.05078L6.66992 18.0508"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.66992 6.05078L18.6699 18.0508"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <h3>{props.errorType}</h3>
        <p>{props.error}</p>
        <div className="responseBtn">
          <button onClick={props.closePopup}>Okay</button>
        </div>
      </div>
    </div>
  );
};
