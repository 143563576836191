import React, { useState } from "react";
import "./styles.css";
import TextField from "../../Components/Form/TextField";
import TextAreaField from "../../Components/Form/TextAreaField";
import SendButton from "../../Components/Form/SendButton";
import sendAni from "../../assets/Images/letter_send.png";
import phoneIcon from "../../assets/Images/bxs_phone-call.png";
import emailIcon from "../../assets/Images/ic_sharp-email.png";
import { useForm, ValidationError } from "@formspree/react";
import ErrorPopup from "../../Components/Popups/ErrorPopup";
import Loader from "../../Components/Form/Loader";

function ContactPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (firstName && lastName && email && phoneNumber && message && subject) {
      console.log("Submitted");
      if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
        setPopup({
          errorType: "Invalid email address",
          error: "Please enter your valid email address",
        });
      } else if (phoneNumber.length != 10) {
        setPopup({
          errorType: "Invalid phone number",
          error:
            "Phone number should be not include country code and must be 10 digit only",
        });
      } else {
        // Form Submission
        var data = {
          FirstName: firstName,
          LastName: lastName,
          CompanyName: company,
          EmailId: email,
          PhoneNumber: phoneNumber,
          Subject: subject,
          Message: message,
        };
        console.log(data, "Sending..");
        setLoader("Sending...");
        fetch("https://formspree.io/f/mleyzvjl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.next === "/thanks?language=en") {
              setLoader("Thank you, we'll get back to you soon");
            }
          });
      }
    } else if (!firstName) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your first name",
      });
    } else if (!lastName) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your last name",
      });
    } else if (!email) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your email address",
      });
    } else if (!phoneNumber) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your phone number",
      });
    } else if (!message) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your message",
      });
    } else if (!subject) {
      setPopup({
        errorType: "Required Field",
        error: "Please enter your subject of you message",
      });
    }
  };
  return (
    <div className="page">
      {popup ? (
        <ErrorPopup
          errorType={popup.errorType}
          error={popup.error}
          closePopup={() => {
            setPopup(false);
          }}
        />
      ) : null}
      <h1 id="contactPageTitle">Contact Us</h1>
      <h2 id="contactPageSubTitle">
        Any question or remarks? Just write us a message!
      </h2>
      <div className="contactSection">
        <div className="contactDetails">
          <h3 id="contactDetailsTitle">Contact Information</h3>
          <h4 id="contactDetailsSubTitle">
            let's connect by your convenent media!
          </h4>
          <div>
            <div className="contactDetailBox">
              <img src={phoneIcon} />
              <p>+1 647-807-7290</p>
            </div>
            <div className="contactDetailBox">
              <img src={emailIcon} />
              <p>Hanan@gladiatorsg.com</p>
            </div>
          </div>
          <img src={sendAni} id="sendAni" />
        </div>
        <div className="contactForm">
          {loader ? (
            <Loader
              loaderText={loader == true ? "Sending" : loader}
              hideLoader={loader == "Thank you, we'll get back to you soon"}
            />
          ) : null}
          <form onSubmit={handleFormSubmit}>
            <div id="column">
              <TextField
                value={firstName}
                setValue={setFirstName}
                placeholder="First Name*"
              ></TextField>
              <div id="spaceBetField"></div>
              <TextField
                value={lastName}
                setValue={setLastName}
                placeholder="Last Name*"
              ></TextField>
            </div>
            <div id="column">
              <TextField
                value={company}
                setValue={setCompany}
                placeholder="Company Name"
              ></TextField>
            </div>
            <div id="column">
              <TextField
                value={email}
                setValue={setEmail}
                placeholder="Email Address*"
              ></TextField>
              <div id="spaceBetField"></div>
              <TextField
                value={phoneNumber}
                fieldType="number"
                setValue={setPhoneNumber}
                placeholder="Phone Number*"
              ></TextField>
            </div>
            <div id="column">
              <TextField
                value={subject}
                setValue={setSubject}
                placeholder="Subject"
              ></TextField>
            </div>
            <div id="column">
              <TextAreaField
                value={message}
                setValue={setMessage}
                placeholder="Message*"
              ></TextAreaField>
            </div>
            <div id="sendButtonContainer">
              <SendButton
                onClick={handleFormSubmit}
                text="Send Message"
              ></SendButton>
              <br />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
